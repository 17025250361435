<template lang="html">
<v-tabs-group>
  <template v-slot:tabs>
    <v-tab key="approved">รายการผู้ป่วยรับวัคซีน</v-tab>
    <v-tab key="review">รายการรอยืนยันวันนี้</v-tab>
    <v-tab key="norecord">รายการสงสัยไม่ได้ลงข้อมูลวันนี้</v-tab>
  </template>
  <template v-slot:items>
    <v-tab-item key="approved">
      <v-model-table ref="patientsTable" model-name="VaccinePatientsList" :model-api="['models','Document','Documents']"
        :model-api-search="vaccinePatientsListQuery"
        api-order-by="created_at"
        model-title="รายการผู้รับวัคซีน COVID-19"
        :headers="headers" :insertable="false" serverPagination>
        <template v-slot:toolBarItem>
          <v-date-field v-model="beginDate" label="ตั้งแต่วันที่" class="px-1" single-line hide-details></v-date-field>
          <v-date-field v-model="endDate" label="ถึงวันที่" class="px-1" single-line hide-details></v-date-field>
          <v-master-field v-model="productCode" groupKey="covid19Vaccine" label="ชนิดวัคซีน" single-line hide-details clearable></v-master-field>
        </template>
        <template v-slot:toolBarButton>
          <v-btn color="primary" @click="exportToCsv" class="mx-1">Export CSV</v-btn>
          <v-btn color="primary" @click="printPatientsList">พิมพ์รายงาน</v-btn>
        </template>
        <template v-slot:item.patient.name_th="props">
          <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
        </template>
        <template v-slot:item.data.productCode="props">
          <v-label-master group-key="covid19Vaccine" :item-code="props.item.data.productCode"></v-label-master>
        </template>
        <template v-slot:item.action="props">
          <v-btn @click.stop="props.actions.deleteData(props.item)" color="error" class="mx-1" v-permission="'covid19vaccine-deleterecord'"><v-icon class="mx-1">$vuetify.icons.delete</v-icon> ลบ</v-btn>
        </template>
      </v-model-table>
    </v-tab-item>
    <v-tab-item key="review">
      <v-model-table ref="reviewPatientsTable" model-name="ReviewPatientsList" :model-api="['models','Document','Documents']"
        :model-api-search="reviewPatientsListQuery"
        api-order-by="created_at"
        model-title="รายการผู้รอยืนยันการรับวัคซีนวันนี้"
        :headers="headers" :insertable="false" serverPagination>
        <template v-slot:item.patient.name_th="props">
          <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
        </template>
        <template v-slot:item.data.productCode="props">
          <v-label-master group-key="covid19Vaccine" :item-code="props.item.data.productCode"></v-label-master>
        </template>
        <template v-slot:item.action="props">
          <v-btn :to="{name:'covidvaccine.administration', params: {hn: props.item.hn}}" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon></v-btn>
          <v-btn @click.stop="props.actions.deleteData(props.item)" color="error" class="mx-1" v-permission="'covid19vaccine-deleterecord'"><v-icon class="mx-1">$vuetify.icons.delete</v-icon></v-btn>
        </template>
      </v-model-table>
    </v-tab-item>
    <v-tab-item key="norecord">
      <v-model-table ref="noRecordPatientsTable" model-name="NoRecordPatientsList" :model-api="['models','Patient','PatientsTrackers']"
        api-scope="NoRecord" api-with="patient"
        api-order-by="created_at"
        model-title="รายการสงสัยไม่ได้ลงข้อมูล"
        :headers="noRecordHeaders" :insertable="false" serverPagination>
        <template v-slot:item.created_at="props">
          <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
        </template>
        <template v-slot:item.patient.name_th="props">
          <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
        </template>
        <template v-slot:item.created_by="props">
          <v-label-user :username="props.item.created_by"></v-label-user>
        </template>
        <template v-slot:item.action="props">
          <v-btn :to="{name:'covidvaccine.administration', params: {hn: props.item.hn}}" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon></v-btn>
        </template>
      </v-model-table>
    </v-tab-item>
  </template>
</v-tabs-group>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime'
import helperPatient from '@/modules/helperPatient'
import printDocument from '@/components/mixins/printDocument'
import { isUndefined, cloneDeep, isArray  } from 'lodash'
import * as papa from 'papaparse'
import FileSaver from 'file-saver'

export default {
  mixins: [printDocument],
  data: ()=>({
    beginDate: undefined,
    endDate: undefined,
    productCode: undefined,
    headers: [
      {
        text: 'วันที่ฉีดวัคซีน',
        value: 'data.adminDateTime',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'ชนิดวัคซีน',
        value: 'data.productCode',
        class: 'body-2'
      },
      {
        text: 'Lot No',
        value: 'data.lotNo',
        class: 'body-2'
      },
      {
        text: 'Serial No',
        value: 'data.serialNo',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '180px',
        sortable: false,
        class: 'body-2'
      }
    ],
    noRecordHeaders: [
      {
        text: 'วัน-เวลาที่รับบริการ',
        value: 'created_at',
        class: 'body-2',
        width: '250px'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'ผู้ให้บริการ',
        value: 'created_by',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  computed: {
    vaccinePatientsListQuery() {
      let tmpQuery = {
        templateCode: 'cv19-vaccine-administration',
        status: 'approved',
        'whereDate#created_at#>=': helperDateTime.today().format('YYYY-MM-DD')
      }
      if (!isUndefined(this.beginDate) && this.beginDate!=null) tmpQuery['whereDate#created_at#>='] = this.beginDate 
      if (!isUndefined(this.endDate) && this.endDate!=null) tmpQuery['whereDate#created_at#<='] = this.endDate 
      if (!isUndefined(this.productCode) && this.productCode!=null) tmpQuery['whereJsonContains#data->productCode'] = this.productCode 

      return tmpQuery
    },
    reviewPatientsListQuery() {
      let tmpQuery = {
        templateCode: 'cv19-vaccine-administration',
        status: 'review',
        'whereDate#created_at#=': helperDateTime.today().format('YYYY-MM-DD')
      }
      
      return tmpQuery
    }
  },
  watch: {
    beginDate: function() {
      this.endDate = undefined
    }
  },
  methods: {
    printPatientsList() {
      let printData = {
        beginDate: this.beginDate,
        endDate: this.endDate,
        patientsList: this.$refs.patientsTable.apiData[this.$refs.patientsTable.modelName]
      }
      this.printTemporary(null,null,'cv19-vaccine-patientslist',printData)
    },
    exportToCsv() {
      let exportData = cloneDeep( this.$refs.patientsTable.apiData[this.$refs.patientsTable.modelName])
      if (isArray(exportData)) {
        exportData = exportData.map(async (item) => {
          return {
            adminDateTime: item.data.adminDateTime,
            hn: item.hn,
            name: await helperPatient.name(item.patient.name_th),
            dateOfBirth: item.patient.dateOfBirth,
            productCode: item.data.productCode,
            lotNo: item.data.lotNo,
            serialNo: item.data.serialNo,
            created_by: item.created_by
          }
        })
      }
      Promise.all(exportData).then((exportedData)=>{
        let content = papa.unparse(exportedData,{quotes: true})
        var blob = new Blob([String.fromCharCode(0xFEFF),content], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, this.$refs.patientsTable.modelName + '.csv');
      }) 
    }
  },
  beforeMount() {
    this.beginDate = helperDateTime.today().format('YYYY-MM-DD')
    this.endDate = helperDateTime.today().format('YYYY-MM-DD')
  },
}
</script>

<style lang="css" scoped>
</style>
